// SECTION NEW
export const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'
export const DEFAULT_VAT_RATE = 'EIGHT_PERCENT' // 8
export const DEFAULT_UNIT = 'Vé'
export const PAYMENT_METHOD_DEFAULT = 'CK_AND_TM'
export const DIFFERENT_FREE = 55000 // miễn phí chênh
export const DIFFERENT_RATE = 0.05 // tỷ lệ thanh toán khi chênh phí (mặc định 5%)

export const URL_DEEP_LINK = {
  CERTIFICATE_MANAGE: '/certificate-mng', // quản lý chứng thư số
  // CERTIFICATE_MANAGE: '/certificate-mng', // đăng ký phát hành
  DECLARATION: '', // Tờ khai
  AUTHORIZATION_DECLARATION: '', // Tờ khai uỷ nhiệm
  INVOICE_TEMPLATE_MANAGE: '/invoice-template-mng', // Mẫu hoá đơn

}
// filter
export const INVOICE_STATUS = [
  { label: 'NEW', value: 'NEW' },
  { label: 'SIGNED', value: 'SIGNED' },
  { label: 'ADJUSTMENT', value: 'ADJUSTMENT' },
  { label: 'ADJUSTED', value: 'ADJUSTED' },
  { label: 'REPLACEMENT', value: 'REPLACEMENT' },
  { label: 'REPLACED', value: 'REPLACED' },
  { label: 'CANCELLED', value: 'CANCELLED' },
]

export const INVOICE_STATUS_VALUES = INVOICE_STATUS.reduce((a, c) => ({ ...a, [c.value]: c.label }), {})

export const RELEASE_STATUS = [
  { label: 'NEW', value: 'NEW' },
  { label: 'SENT', value: 'SENT' },
  { label: 'CQT_ISSUED_CODE', value: 'CQT_ISSUED_CODE' },
  { label: 'CQT_REJECT_ISSUE_CODE', value: 'CQT_REJECT_ISSUE_CODE' },
]

export const SIGN_STATUS = [
  { label: 'UNSIGNED', value: 'UNSIGNED' },
  { label: 'SIGNED', value: 'SIGNED' },
]

export const SIGN_TYPE_OPTIONS = [
  { label: 'FILE', value: 'FILE' }, // file mềm
  { label: 'USB_TOKEN', value: 'USB_TOKEN' }, // USB
]

export const SIGN_TYPE = SIGN_TYPE_OPTIONS.reduce((a, c) => ({ ...a, [c.value]: c.label }), {})

// ẩn hiện các nút chức năng
export const BUTTON_ACTION_PERMISSIONS = {
  // EDIT, SIGN, SEND_EMAIL, RELEASE, VIEW_PDF, ADJUST, REPLACE, DUPLICATE, CANCEL
  NEW: {
    NEW: {
      EDIT: true,
      SIGN: true,
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
    SENT: {},
    CQT_ISSUED_CODE: {},
    CQT_REJECT_ISSUE_CODE: {},
  },
  SIGNED: {
    NEW: {
      EDIT: true,
      RELEASE: true,
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
    SENT: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
    CQT_ISSUED_CODE: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
      ADJUSTMENT: true,
      REPLACEMENT: true,
      CANCEL: true,
    },
    CQT_REJECT_ISSUE_CODE: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
  },
  ADJUSTMENT: {
    NEW: {
      EDIT: true,
      RELEASE: true,
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
      SIGN: true,
    },
    SENT: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
    CQT_ISSUED_CODE: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
      ADJUSTMENT: true,
    },
    CQT_REJECT_ISSUE_CODE: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
  },
  ADJUSTED: {
    NEW: {},
    SENT: {},
    CQT_ISSUED_CODE: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
    CQT_REJECT_ISSUE_CODE: {},
  },
  REPLACEMENT: {
    NEW: {
      EDIT: true,
      RELEASE: true,
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
    SENT: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
    CQT_ISSUED_CODE: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
      ADJUSTMENT: true,
    },
    CQT_REJECT_ISSUE_CODE: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
  },
  REPLACED: {
    NEW: {},
    SENT: {},
    CQT_ISSUED_CODE: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
    CQT_REJECT_ISSUE_CODE: {},
  },
  CANCELLED: {
    NEW: {},
    SENT: {},
    CQT_ISSUED_CODE: {
      VIEW_PDF: true,
      SEND_EMAIL: true,
      DUPLICATE: true,
    },
    CQT_REJECT_ISSUE_CODE: {},
  },
}

export const SEND_MAIL_STATUS = [
  { label: 'WAITING', value: 'WAITING' },
  { label: 'SENT', value: 'SENT' },
]

export const ADJUSTMENT_TYPE_OPTIONS = [
  { label: 'INFORMATIONAL_ADJUSTMENT', value: 'INFORMATIONAL_ADJUSTMENT' },
  { label: 'INCREASE_ADJUSTMENT', value: 'INCREASE_ADJUSTMENT' },
  { label: 'DECREASE_ADJUSTMENT', value: 'DECREASE_ADJUSTMENT' },
]

export const ADJUSTMENT_TYPE = ADJUSTMENT_TYPE_OPTIONS.reduce((a, c) => ({ ...a, [c.value]: c.label }), {})
// export const DISCOUNT_TYPES = [
//   { label: 'NO_DISCOUNT', value: 'Không có chiết khấu' },
//   { label: 'BY_ITEM', value: 'Theo mặt hàng' },
//   { label: 'BY_TOTAL_INVOICE', value: 'Theo tổng giá trị hoá đơn' },
// ]
export const TAX_RATE_TYPES = {
  MULTIPLE: 'MULTIPLE',
  SINGLE: 'SINGLE',
}

export const TAX_TYPES = [
  { label: 'ZERO_PERCENT', value: 0 },
  // { label: 'FIVE_PERCENT', value: 5 },
  { label: 'EIGHT_PERCENT', value: 8 },
  // { label: 'TEN_PERCENT', value: 10 },
  // { label: 'NON_TAXABLE', value: 0 }, // Không chịu thuế
  // { label: 'NON_DECLARED', value: 0 }, // Không kê khai nộp thuế
  // { label: 'OTHER', value: 0 }, // Khác
]

export const TAX_TYPES_OBJECT = TAX_TYPES.reduce((a, c) => ({ ...a, [c.label]: c.value }), {})

export const GOODS_SERVICE_TYPES = {
  GOODS_SERVICE: 'GOODS_SERVICE', // Hàng hoá dịch vụ
  PROMOTION: 'PROMOTION', // Khuyến mại
  TRADE_DISCOUNT: 'TRADE_DISCOUNT', // Chiết khấu thương mại (theo dòng)
  NOTE: 'NOTE', // Ghi chú, diễn giải
}

export const PAYMENT_METHOD_LIST = [
  { label: 'TM', value: 'TM' },
  { label: 'CK', value: 'CK' },
  { label: 'TM/CK', value: 'CK_AND_TM' },
]

export const CUSTOMER_TYPE_OPTIONS = [
  { label: 'KL', value: 'KHACH_LE' },
  { label: 'CTV', value: 'CTV' },
]

export const FLIGHT_TYPE = {
  Domestic: 'Vé máy bay nội địa',
  Inbound: 'Vé máy bay quốc tế',
  Other: 'Khác',
}

export const INVOICE_LIST_COLUMNS = [
  {
    key: 'checkbox', stickyColumn: true, tdClass: 'bg-white width-30', default: true, fixed: true,
  },
  {
    key: 'symbol', stickyColumn: false, tdClass: 'bg-white', default: true,
  }, // Mẫu số/ ký hiệu
  {
    key: 'invNumber', stickyColumn: true, tdClass: 'bg-white', class: 'position-left-45', default: true,
  }, // Số hoá đơn
  { key: 'createdAt', default: true }, // ngày lập
  { key: 'releasedInvCode' }, // Mã CQT
  { key: 'buyerUnitCode' }, // Mã khách hàng
  { key: 'buyerName', default: true }, // Khách hàng (tên người mua)
  { key: 'buyerUnitName', default: true }, // Khách hàng (tên công ty)
  { key: 'buyerTaxCode' }, // Mã số thuế
  { key: 'buyerAddress' }, // địa chỉ
  { key: 'buyerEmail' }, // email
  { key: 'buyerPhoneNumber' }, // số điện thoại
  { key: 'paymentMethod' }, // hình thức thanh toán
  { key: 'bankAccountNumber' }, // số tk ngân hàng
  { key: 'bankName' }, // tên ngân hàng
  { key: 'totalAmount', default: true }, // Tổng tiền
  { key: 'invoiceStatus', default: true }, // Trạng thái hoá đơn
  { key: 'releaseStatus', default: true }, // Trạng thái phát hành
  { key: 'signStatus' }, // Trạng thái ký hoá đơn
  { key: 'sendMailStatus', default: true }, // Trạng thái gửi email
  { key: 'referenceCode', default: true }, // Mã tra cứu
  { key: 'tags', default: true }, // Nhãn
  {
    key: 'action', stickyColumn: true, tdClass: 'bg-white', class: 'position-right-0', default: true, fixed: true,
  }, // Thao tác
]

export const INVOICE_LIST_FIXED_COLUMNS = INVOICE_LIST_COLUMNS.filter(col => col.fixed).map(col => col.key)
// !SECTION

// ANCHOR OLD
export const INVOICE_TABLE_COLUMNS = [
  { label: 'checkbox', key: 'checkbox' },
  { label: 'invoiceNumber', key: 'invoiceNumber' },
  { label: 'issueDate', key: 'publicDate' },
  { label: 'customer', key: 'customer' },
  { label: 'total', key: 'totalPayment' },
  { label: 'totalPreTax', key: 'totalAmountBeforeTax' },
  { label: 'tax', key: 'totalVat' },
  { label: 'collectionFee', key: 'collectionFee' },
  { label: 'taCode', key: 'taCode' },
  { label: 'type', key: 'type' },
  { label: 'status', key: 'status' },
  { label: 'emailStatus', key: 'emailStatus' },
  { label: 'errNoti', key: 'errNoti' },
  { label: 'created', key: 'createdAt', sortable: true },
  { label: 'updated', key: 'updatedAt', sortable: true },
  { label: 'actions', key: 'actions' },
]

export const DATE_OPTION_VALUES = {
  creationDate: 'createdAt',
  releaseDate: 'publicDate',
}

export const DATE_OPTIONS = [
  {
    value: DATE_OPTION_VALUES.creationDate,
    label: 'invoice.dateOptions.creationDate',
  },
  {
    value: DATE_OPTION_VALUES.releaseDate,
    label: 'invoice.dateOptions.releaseDate',
  },
]

export const INVOICE_TYPE_VALUES = {
  original: 'ROOT', // loại hoá đơn: GỐC
  adjustment: 'ADJUST', // loại hoá đơn: ĐIỀU CHỈNH
  replacement: 'REPLACE', // loại hoá đơn: THAY THẾ
  cancellation: 'CANCEL', // loại hoá đơn: XOÁ BỎ (BỊ HUỶ)
  adjusted: 'ADJUSTED', // loại hoá đơn: BỊ ĐIỀU CHỈNH
}

export const INVOICE_TYPE_OPTIONS = [
  {
    value: INVOICE_TYPE_VALUES.original,
    label: 'invoice.invoiceTypes.original',
  },
  {
    value: INVOICE_TYPE_VALUES.adjustment,
    label: 'invoice.invoiceTypes.adjustment',
  },
  {
    value: INVOICE_TYPE_VALUES.replacement,
    label: 'invoice.invoiceTypes.replacement',
  },
  {
    value: INVOICE_TYPE_VALUES.cancellation,
    label: 'invoice.invoiceTypes.cancellation',
  },
  {
    value: INVOICE_TYPE_VALUES.adjusted,
    label: 'invoice.invoiceTypes.adjusted',
  },
]
// export const INVOICE_STATUS_VALUES = {
//   draft: 'DRAFT', // trạng thái NHÁP
//   awaitingApproval: 'WAITING_TO_APPROVE', // trạng thái CHỜ DUYỆT
//   approved: 'APPROVED', // trạng thái CHỜ KÝ | trạng thái ĐÃ DUYỆT
//   refuseApproval: 'REFUSE_TO_ISSUE', // trạng thái BỊ TỪ CHỐI DUYỆT
//   signed: 'SIGN', // trạng thái ĐÃ KÝ
//   refuseSignature: 'REFUSE_TO_SIGN', // trạng thái BỊ TỪ CHỐI KÝ
//   awaitingRelease: 'WAITING_TO_ISSUE', // trạng thái CHỜ PHÁT HÀNH
//   released: 'ISSUE', // trạng thái ĐÃ PHÁT HÀNH
//   refuseRelease: 'ERROR_TO_ISSUE', // trạng thái BỊ TỪ CHỐI PHÁT HÀNH
// }
// SEND_TO_SIGN, WAITING_TO_APPROVE, WAITING_TO_ISSUE, ISSUE, ERROR_TO_ISSUE, REFUSE_TO_ISSUE

export const INVOICE_STATUS_OPTIONS = [
  {
    value: INVOICE_STATUS_VALUES.draft,
    label: 'invoice.status.draft',
  },
  {
    value: INVOICE_STATUS_VALUES.awaitingApproval,
    label: 'invoice.status.awaitingApproval',
  },
  {
    value: INVOICE_STATUS_VALUES.approved,
    label: 'invoice.status.approved',
  },
  {
    value: INVOICE_STATUS_VALUES.refuseApproval,
    label: 'invoice.status.refuseApproval',
  },
  {
    value: INVOICE_STATUS_VALUES.signed,
    label: 'invoice.status.signed',
  },
  {
    value: INVOICE_STATUS_VALUES.refuseSignature,
    label: 'invoice.status.refuseSignature',
  },
  {
    value: INVOICE_STATUS_VALUES.awaitingRelease,
    label: 'invoice.status.awaitingRelease',
  },
  {
    value: INVOICE_STATUS_VALUES.released,
    label: 'invoice.status.released',
  },
  {
    value: INVOICE_STATUS_VALUES.refuseRelease,
    label: 'invoice.status.refuseRelease',
  },
]

export const EMAIL_STATUS_VALUES = {
  all: undefined,
  sent: true,
  notSent: false,
}

export const EMAIL_STATUS_OPTIONS = [
  {
    value: EMAIL_STATUS_VALUES.all,
    label: 'invoice.emailStatusOptions.all',
  },
  {
    value: EMAIL_STATUS_VALUES.sent,
    label: 'invoice.emailStatusOptions.sent',
  },
  {
    value: EMAIL_STATUS_VALUES.notSent,
    label: 'invoice.emailStatusOptions.notSent',
  },
]

export const INVOICE_ACTIONS_HISTORY_TABLE_COLUMNS = [
  { label: 'orderNo', key: 'orderNo', style: 'width: 3em;' },
  { label: 'date', key: 'date' },
  { label: 'action', key: 'action' },
  { label: 'amount', key: 'amount' },
  { label: 'executor', key: 'executor' },
]

export const INVOICE_GOODS_SERVICES_TABLE_COLUMNS = [
  {
    label: 'orderNo',
    key: 'orderNo',
  },
  {
    label: 'description',
    key: 'description',
    style: 'min-width: 18em;',
  },
  {
    label: 'unit',
    key: 'unit',
    style: 'min-width: 6.5em;',
  },
  {
    label: 'quantity',
    key: 'quantity',
    style: 'width: 5.5em;',
  },
  {
    label: 'unitPrice',
    key: 'unitPrice',
    style: 'min-width: 7em;',
  },
  {
    label: 'amountPreTax',
    key: 'beforeTaxAmount',
    style: 'min-width: 9.25em;',
    styleVn: 'min-width: 10.75em;',
  },
  {
    label: 'VATrate',
    key: 'vatRate',
    style: 'max-width: 5em;',
    styleVn: 'max-width: 6em;',
  },
  {
    label: 'VAT',
    key: 'vatAmount',
    style: 'min-width: 6.5em;',
  },
  {
    label: 'amount',
    key: 'amount',
    style: 'min-width: 6.5em;',
  },
]

export const INVOICE_GOODS_SERVICES_TABLE_COLUMNS_EXTEND_ACTION = [
  ...INVOICE_GOODS_SERVICES_TABLE_COLUMNS,
  { label: 'action', key: 'action' },
]

export const PAYMENT_METHOD_VALUES = {
  cash: 'CASH',
  transfer: 'BANK_TRANSFER',
  all: 'CASH_BANK_TRANSFER_MIXED',
}

export const PAYMENT_METHOD_OPTIONS = [
  {
    value: PAYMENT_METHOD_VALUES.cash,
    label: 'invoice.paymentMethodOptions.cash',
  },
  {
    value: PAYMENT_METHOD_VALUES.transfer,
    label: 'invoice.paymentMethodOptions.transfer',
  },
  {
    value: PAYMENT_METHOD_VALUES.all,
    label: 'invoice.paymentMethodOptions.all',
  },
]

export function parsePaymentMethod(value) {
  const option = PAYMENT_METHOD_OPTIONS.find(item => item.value === value)
  if (option === undefined) return ''
  return this.$t(option.label)
}

export const TICKET_TYPE_VALUES = {
  domestic: 'DOMESTIC',
  international: 'INTERNATIONAL',
  mix: 'MIX',
}

export const TICKET_TYPE_OPTIONS = [
  {
    value: TICKET_TYPE_VALUES.domestic,
    label: 'invoice.ticketTypeOptions.domestic',
  },
  {
    value: TICKET_TYPE_VALUES.international,
    label: 'invoice.ticketTypeOptions.international',
  },
]

export const TICKET_TYPE_OPTIONS_EXTEND_MIX = [
  ...TICKET_TYPE_OPTIONS,
  {
    value: TICKET_TYPE_VALUES.mix,
    label: 'invoice.ticketTypeOptions.mix',
  },
]

export const CHARACTERISTIC_VALUES = {
  goods: 'SERVICE',
  fee: 'FEE',
  discount: 'DISCOUNT',
  description: 'DESCRIPTION',
}

export const CHARACTERISTIC_OPTIONS = [
  {
    value: CHARACTERISTIC_VALUES.goods,
    label: 'invoice.characteristicOptions.goods',
  },
  {
    value: CHARACTERISTIC_VALUES.fee,
    label: 'invoice.characteristicOptions.fee',
  },
  {
    value: CHARACTERISTIC_VALUES.discount,
    label: 'invoice.characteristicOptions.discount',
  },
  {
    value: CHARACTERISTIC_VALUES.description,
    label: 'invoice.characteristicOptions.description',
  },
]

export const INVOICE_SEND_EMAIL_TABLE_COLUMNS = [
  { label: 'orderNo', key: 'orderNo', style: 'width: 3em;' },
  { label: 'receiver', key: 'receiver' },
  { label: 'recipientEmail', key: 'recipientEmail' },
  { label: 'cc', key: 'cc' },
  { label: 'bcc', key: 'bcc' },
  { label: 'invoiceNo', key: 'invoiceNo' },
  { label: 'serial', key: 'formSerial' },
  { label: 'totalPayment', key: 'totalPayment' },
  { label: 'emailStatus', key: 'emailStatus' },
  { label: 'preview', key: 'preview' },
]

export const CHOOSE_TICKET_TABLE_COLUMNS = [
  { label: 'checkbox', key: 'checkbox' },
  { label: 'ticketNo', key: 'ticketName' },
  { label: 'invoiceAirline', key: 'airline' },
  { label: 'trips', key: 'trips' },
  { label: 'invoiceIssueDate', key: 'issueDate' },
  { label: 'unitPrice', key: 'priceTicket' },
  { label: 'invoiceVAT', key: 'tax' },
  { label: 'invoiceCollectionFee', key: 'collectionFee' },
  { label: 'invoiceTotalPayment', key: 'totalPayment' },
  { label: 'customer', key: 'customer' },
]
